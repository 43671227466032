import { MDCMenu } from '@material/menu';

let CheckboxFilters = {
    "mdcMenu": undefined,
    "open": false,
    "setLabel": function () {

        var $checkedItems = $('#statusList').find(".mdc-list-item[aria-checked='true']"),
            label = "All";

        if ($checkedItems.length == 1) {
            label = $checkedItems.eq(0).find('.mdc-list-item__text').text();
        }

        if ($checkedItems.length > 1) {
            label = "Multiple";
        }

        $('#StatusId').val(label)
    },
    "init": function () {
        CheckboxFilters.mdcMenu = new MDCMenu(document.querySelector('#statusList'));
        
        $("#StatusId").on("click", () => {
            CheckboxFilters.mdcMenu.open = true;
        });

        CheckboxFilters.mdcMenu.listen('MDCMenu:selected', () => {
            CheckboxFilters.setLabel();
        });

        CheckboxFilters.setLabel();

    }
}

export default CheckboxFilters;
