import { MDCMenu } from '@material/menu';

let MenuItems = {
    "mdcMenu": undefined,
    "openers": {},
    "init": function () {
        
        if (document.getElementById("member-menu")){
            MenuItems.initMenu('member');
        }
        if (document.getElementById("application-menu")){
             MenuItems.initMenu('application');
        }
        if (document.getElementById("reports-menu")){
            MenuItems.initMenu('reports');
        }
        MenuItems.highlightNavItem();
    },
    "initMenu": function (key) {
        const menu = new MDCMenu(document.querySelector(`#${key}-menu`));
        if (document.getElementById(key+"-menu-toggle")) {
            document.getElementById(key +"-menu-toggle").addEventListener("click", (e) => {
                MenuItems.openers[key] = !MenuItems.openers[key];
                menu.open = MenuItems.openers[key]; //toggle menu
                e.stopPropagation();
            });
        }


        document.body.addEventListener("click", (e) => {
            menu.open = false;
            MenuItems.openers[key] = false;
        });
    },
    "highlightNavItem": function() {
        const $header = $('#main-header');

        $header.find('a').each(function () {
            const btn = $(this).parents('.mdc-menu-surface--anchor').find('button');
            if ($(this).prop('href') == window.location.href) {
                $(this).addClass('active-menu');
                if (btn){
                    btn.addClass('active-menu');
                }
            }

            
        });
    }
    
}

export default MenuItems;
