import { MDCMenu } from '@material/menu';

let UserMenu = {
    "mdcMenu": undefined,
    "hamburgerMenu": undefined,
    "open": false,
    "hbopen": false,
    "init": function () {
        UserMenu.mdcMenu =  new MDCMenu(document.querySelector('#user-menu'));
        UserMenu.hamburgerMenu = new MDCMenu(document.querySelector('#hamburger-menu'));

        if (document.getElementById("user-menu-toggle")) {
            document.getElementById("user-menu-toggle").addEventListener("click", (e) => {
                UserMenu.open = !UserMenu.open;
                UserMenu.mdcMenu.open = UserMenu.open; //toggle menu
                e.stopPropagation();
            });
        }

        if (document.getElementById("hamburger-menu-toggle")) {
            document.getElementById("hamburger-menu-toggle").addEventListener("click", (e) => {
                UserMenu.hbopen = !UserMenu.hbopen;
                UserMenu.hamburgerMenu.open = UserMenu.hbopen; //toggle menu
                e.stopPropagation();
            });
        }

        document.body.addEventListener("click", (e)=> {
            UserMenu.hamburgerMenu.open = false;
            UserMenu.hbopen = false;

            UserMenu.open = false;
            UserMenu.mdcMenu.open = false; //toggle menu

        });
    }
}

export default UserMenu;
