import { MDCSelect } from '@material/select';

let SelectFilters = {
    "MDCSelect": undefined,
    "open": false,
    "init": function () {

        SelectFilters.MDCSelect = new MDCSelect(document.querySelector('.mdc-select'));
    }
}

export default SelectFilters;
