
export default {
    "_postData":  async function postData(url = "", data = {}) {
        const response = await fetch(url, {
            method: "POST", 
            mode: "cors", 
            cache: "no-cache", 
            credentials: "same-origin", 
            headers: {
            "Content-Type": "application/json",
            
            },
            redirect: "follow", 
            referrerPolicy: "no-referrer", 
            body: JSON.stringify(data),
        });
        return {}
      
    },
    "_post":  async function postData(url = "") {
        const response = await fetch(url, {
            method: "POST", 
            mode: "cors", 
            cache: "no-cache", 
            credentials: "same-origin", 
            headers: {
            "Content-Type": "application/json",
            
            },
            redirect: "follow", 
            referrerPolicy: "no-referrer"
        });
        return {}
      
    },  
    "init": function () {
        $('[data-action="deleteLink"]').on("click", (e)=> {
            const id = $(e.target).data('id');
            this.deleteLink(id);
        });

        this.initDragList();
    },
    "initDragList": function () {
        const that = this;
        $(document).ready(function () {
            $('#linklist').tableDnD({
                onDragClass: "myDragClass",
                onDrop: function (table, row) {
                    window.setTimeout(()=> {
                        const order = [];
                        //console.log(table.rows);
                        //console.log($('#linklist')[0].rows);
                        for (let row of table.rows) {
                            if (row.id !== ''){
                                order.push(Number(row.id));
                            }
                        }
                    
                        that.doSort(order);
                    },200);
                },
                onDragStart: function (table, row) {
                    $(table).parent().find('.result').text("Started dragging row " + row.id);
                }
            });
        });
    },
    "deleteLink": function(id){
        this._post("/api/net-zero/delete-link/" + id).then((data) => {
            window.location.reload();
        });
    },
    "doSort": function(sortList){

        const sortData = {
            "links" : []
        }
        for (var i = 0; i < sortList.length; i++ ){
            sortData.links.push({
                "id": sortList[i],
                "sort": i + 1
            })
        };
        this._postData("/api/net-zero/sort-links",  sortData ).then((data) => {
            console.log(data)
        });
    }
}
