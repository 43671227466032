import { MDCDialog } from '@material/dialog';

let EditUser = {

    "init": function () {
        
        const confirmDeleteUserDialog = new MDCDialog(document.querySelector('#confirmDeleteUser #confirm-delete-user-mdc-dialog'));
        const deleteUserDialog = new MDCDialog(document.querySelector('#deleteUserDialog #delete-user-mdc-dialog'));

        $("#deleteUser").click(function (evt) {
            confirmDeleteUserDialog.lastFocusedTarget = evt.target;
            confirmDeleteUserDialog.open();
        });

        // Confirm deleting
        $("#ConfirmDeletaUser").click(function (evt) {
            confirmDeleteUserDialog.close();

            $.ajax({
                url: "/api/users/delete/" + userId,
                type: "POST",

                success: function () {
                    window.location = window.location.origin + redirectUrl;
                },

                error: function (e) {
                    deleteUserDialog.open();
                    
                    var content = $("#deleteUserDialog #delete-user-mdc-dialog-description");

                    if (e.status == 400) {                   
                        content.html(e.responseJSON.message);

                    } else {
                        content.html("Currently Unavailable, please try again");
                    }
                }
            });
        });

        // Cancel dialog
        $(".mdc-dialog__footer__button--cancel").click(function (evt) {
            confirmDeleteUserDialog.close();
            deleteUserDialog.close();
        });
    }
}

export default EditUser;
