import BlockEditor from './blockEditor.js';
import trumbowyg from 'trumbowyg';
import trumbowygtable from './libs/trumbowyg.table.js';
import select2 from './libs/select2.full.min.js';
import validate from 'jquery-validation';
import validateUnobtrusive from 'jquery-validation-unobtrusive';
import { MDCSnackbar } from '@material/snackbar';
import { MDCDialog } from '@material/dialog';


class applicationsEditor {
    constructor() {
        this.bindEvents();

        $.trumbowyg.svgPath = '/dist/img/icons.svg';
        
        $.trumbowyg.opts = {
            btns: [
                ['viewHTML'],
                ['formatting2'],
                ['strong', 'em', 'del'],
                ['link'],
                ['unorderedList', 'orderedList'],
                ['table'],
                ['horizontalRule'],
                ['removeformat'],
                ['fullscreen']
            ],
            btnsDef: {
                formatting2: {
                    dropdown: ['p', 'blockquote', 'h2', 'h3', 'h4'],
                    ico: 'p'
                }
            },
            semantic: true,
            removeformatPasted: true,
            tagsToRemove: ['script', 'link'],

        };
        this.loadWysiwyg();
        $('[data-select-replace]').select2();
        if (document.querySelector('#unpublishPopup aside')) {
            this.unpublishDialog = new MDCSnackbar(document.querySelector('#unpublishPopup aside'));
            this.saveDialog = new MDCSnackbar(document.querySelector('#saveDialog aside'));
            this.publishDialog = new MDCSnackbar(document.querySelector('#publishDialog aside'));

            this.unpublishDialog = new MDCDialog(document.querySelector('#unpublishDialog #unpublish-mdc-dialog'));
            this.deleteDialog = new MDCDialog(document.querySelector('#deleteDialog #delete-mdc-dialog'));
            this.validationDialog = new MDCSnackbar(document.querySelector('#validationDialog aside'));
        }

        this.blockEditor = new BlockEditor('blockEditor', {});
        this.applicationDetails = {
            filters: [],
            products: [],
            grades: []
        }

        if (window.caseStudyId !== undefined) {
            this.getApplication(window.caseStudyId)
                .then(() => {
                    this.getIndustries();
                    this.getFeatures();
                    this.getBrands();
                    this.getSustainability();
                    this.getApplications();
                    this.getProducts();
                    this.getGrades();
                }).catch((error) => {
                    alert("An error occurred retrieving the selected Application");
                });
        } else {
            this.getIndustries();
            this.getFeatures();
            this.getBrands();
            this.getSustainability();
            this.getApplications();
            this.getProducts();
            this.getGrades();
            this.handleButtonVisibility();
        }

    }

    loadWysiwyg() {
        $('[data-wysiwyg]').each((i, el) => {
            if (!$(el).hasClass('trumbowyg')) {
                $(el).trumbowyg($.trumbowyg.opts)
                    .on('tbwchange', this.onFieldUpdate);
            }
        });
    }

    bindEvents() {
        var that = this;
        $('[data-update-field]').on('keyup',  (e) => {
            
            const val = $(e.target).val();
            const $target = $('#' + $(e.target).attr('data-update-field'));
            $target.text(val);
        });

        $('[data-url-slug]').on('keyup',  (e) => {
            const value = $(e.target).val();
            $(e.target).val(this.stripUrlChars(value));
        });

        $('body').on("change", "[data-file-upload] input[type='file']", (e) => {
            const el = e.target;
            const [file] = $(el)[0].files;
            that.handleFileUpload(file, el);
        });

        $('[data-save]').on('click', (e) => {
            e.preventDefault();
            this.save(false, $(e.target).attr('data-save'));
        });

        $('[data-publish]').on('click', (e) => {
            e.preventDefault();
            this.save(true, $(e.target).attr('data-publish'));
        });

        $('[data-unpublish]').on('click', (e) => {
            e.preventDefault();
            this.unpublish();
        });

        $('[data-delete-article]').on('click', (e) => {
            e.preventDefault();
            this.delete();
        });

        $('[data-delete-popup]').on('click', (e) => {
            e.preventDefault();
            this.deleteDialog.open();
        });

        $('[data-form-field]').on('change', (e) => {
            this.onFieldUpdate(e);
        });

        $('[data-unpublish-popup]').on('click', (e) => {
            e.preventDefault();
            this.unpublishDialog.open();
        });

        // Cancel dialog
        $("#delete-cancel").on("click", () => {
            this.deleteDialog.close();
        });

        $("#unpublish-cancel").on("click", () => {
            this.unpublishDialog.close();
        });


    }

    onFieldUpdate(event){
        if ($(event.target).attr('data-noupdate') !== undefined){
            return;
        } 

        //console.log("field was updated", event.target);
        $('[data-preview]').addClass('hide');
    }

    enableFieldUpdates() {
        $('#applications-form').find('input,textarea').removeAttr('data-noupdate');
    }

    disableFieldUpdates() {
        $('#applications-form').find('input,textarea').attr('data-noupdate', true);
    }

    getApplication(id) {
       
        if (!id) {
            alert("Error: Cannot find application");
            return;
        }

        return $.get("/api/applications/get-by-id/" + id).then((data) => {
            this.disableFieldUpdates();
            this.blockEditor.updateData(data);
            this.populateApplicationDetails(data);
            this.handleButtonVisibility();
            this.enableFieldUpdates();  
        });
    }

    stripUrlChars(urlSlug) {
        return urlSlug.replace(/[^a-z0-9-]/gi, '');
    }

    getFileAsDataURL(file, callback) {
        var reader = new FileReader();
        reader.onload = function (e) { callback(e.target.result) };
        reader.readAsDataURL(file);
    }

    handleFileUpload(file, el) {
        if ($(el).attr('data-image-preview') !== undefined) {
            var $target = $(el).parents('[data-file-upload]').find('[data-image-preview-img]');

            if (file) {
                this.getFileAsDataURL(file, function (data) {
                    $target.attr('src', data);
                    $(el).attr('data-src', data);
                });
            } else {
                $target.attr('src', '');
                $(el).attr('data-src', '');
            }
        }

        if ($(el).attr('data-details-elem') !== undefined) {
            var $target = $('#' + $(el).attr('data-details-elem'));
            if (file) {
                $target.text('Uploaded File: ' + file.name + ' [' + file.type + '] (' + ((file.size / 1024).toFixed(2)) + 'kb)');
            } else {
                $target.text('');
            }
        }
    }

    populateApplicationDetails(details) {

        this.applicationDetails = details;

        const { title, url, disclaimer, thumbnail, blocks, id, contacts, pdfDoc } = details;
        const href = $('#previewArticle').attr('data-href');
        const $contactItems = $('[data-contact-item]');

        this.populateHeaderInfo();
        this.handleButtonVisibility();

        this.populateField("Title", "name", title, $('#applications-form'));
        this.populateField("URL", "name", url, $('#applications-form'));

        this.populateField("pdfDoc", "name", pdfDoc, $('#applications-form'));
        this.populateField("Disclaimer", "name", disclaimer, $('#applications-form'));

        this.populateFileField("Thumbnail", "name", thumbnail, $('#applications-form'));
        this.populateBlocks(blocks, "name", $('#applications-form'));

        $('#url_preview').text(url);
        $('#previewArticle').attr('href', `${href}${url}`);

        $contactItems.map((i, el) => {
            if (contacts && contacts[i]) {
                this.populateContactField(el.querySelector('[data-region-input]'), contacts[i].region);
                this.populateContactField(el.querySelector('[data-office-input]'), contacts[i].name);
                this.populateContactField(el.querySelector('[data-tel1-input]'), contacts[i].phone);
                this.populateContactField(el.querySelector('[data-tel2-input]'), contacts[i].phone2);
                this.populateContactField(el.querySelector('[data-email-input]'), contacts[i].email);
            }
        });
        
        this.enableFieldUpdates();
        window.mdc.autoInit();
    }

    populateContactField(el, value) {
        el.parentNode.MDCTextField.value = value || "";
    }

    populateField(name, lookupAttr, value, $container) {

        const $field = $container.find('[' + lookupAttr + '="' + name + '"]');

        if ($field.length) {

            if ($field.is("input") || $field.is("select")) {
                $field[0].parentNode.MDCTextField.value = value || "";

            } else if ($field.is("textarea")) {
                if ($field.hasClass('trumbowyg-textarea')) {
                    $field.trumbowyg('html', value);
                  
                } else {
                    $field.val(value);
                }
            }
          
        }
    }

    populateFileField(name, lookupAttr, value, $container) {
        const $field = $container.find('[' + lookupAttr + '="' + name + '"]'),
            $parent = $field.parents("[data-file-upload]");
        if ($field.length) {
            if (value) {
                $parent.find('.image-preview').attr('src', value);
                $field.attr('data-src', value);
            }
        }
    }

    populateBlocks(blocks) {
        if (!blocks) {
            return;
        }
        for (var v = 0; v < blocks.length; v++) {
            const block = blocks[v];
            const blockElem = this.blockEditor.createEditorBlock("block-" + block.type, "bottom").find('li:last');
            this.populateField('text1', "data-field-name", block['text1'], blockElem);
            this.populateField('text2', "data-field-name", block['text2'], blockElem);
            this.populateFileField('imageUrl', "data-field-name", block['imageUrl'], blockElem);

            this.blockEditor.postAdd(blockElem);
        }
    }

    save(publish, type) {
        const { id } = this.applicationDetails;
        $.validator.unobtrusive.parse($("#applications-form"));
        var isValid = $("#applications-form").valid(), that = this;
        if (isValid) {

            $('[data-form-action]').attr('disabled', 'disabled');

            var api = "/api/applications/edit";
            if (type == "create") {
                var api = "/api/applications/create";
            }
            const vm = this;
            $.post(api, this.saveToJson(publish)).then(function (data) {
                $('[data-form-action]').removeAttr('disabled');
                if (type == "create" && data.id) {
                    window.location.href = `/application-case-studies/edit?id=${data.id}`;
                } else {

                    if (publish) {
                        that.publishDialog.open();
                        
                    } else {
                        that.saveDialog.open();
                    
                    }
                    that.getApplication(id);
                }

            }).fail(function (err) {
                if (err.responseJSON.message === 'This url is already exist!') {
                    var el = $('#URL');
                    el.addClass("input-validation-error");
                    var p = $('.url-validation');
                    p[0].innerText = err.responseJSON.message;
                    p.addClass("field-validation-error");

                    vm.validationDialog.open();
                    var $el = $('.input-validation-error').first();

                    $([document.documentElement, document.body]).animate({
                        scrollTop: $el.offset().top - 100
                    }, 200);
                }
            });
        } else {
            this.validationDialog.open();
            var $el = $('.input-validation-error').first();

            $([document.documentElement, document.body]).animate({
                scrollTop: $el.offset().top - 100
            }, 200);

        }
    }

    handleButtonVisibility() {

        if (window.mode == "create") {
            $('[data-save]').removeClass('hide');
        } else {

            $('[data-form-action]').removeClass('hide');
           
            if (this.applicationDetails.published === false) {
                $('[data-unpublish-popup]').addClass('hide');
            }
        }
    }

    getIndustries() {
        $.get("/api/applications/get-industries").then((data) => {
            this.renderIndustriesDropdown(data, '#Filter_Industries', this.applicationDetails.filters.industries);
        });
    }

    getFeatures() {
        $.get("/api/applications/get-features").then((data) => {
            this.renderDropdown(data, '#Filter_Features', this.applicationDetails.filters.features);
        });
    }

    getApplications() {
        $.get("/api/applications/get-applications").then((data) => {
            this.renderDropdown(data, '#Filter_Applications', this.applicationDetails.filters.applications);
        });
    }

    getSustainability() {
        $.get("/api/applications/get-sustainability").then((data) => {
            this.renderDropdown(data, '#Filter_Sustainability', this.applicationDetails.filters.sustainability);
        });
    }

    getBrands() {
        $.get("/api/applications/get-brands").then((data) => {
            this.renderDropdown(data, '#Filter_Brands', this.applicationDetails.filters.brands);
        });
    }

    getProducts() {
        $.get("/api/applications/get-products").then((data) => {
            this.renderDropdown(data, '#Filter_Products', this.applicationDetails.products);
        });
    }

    getGrades() {
        $.get("/api/applications/get-grades").then((data) => {
            this.renderDropdown(data, '#Filter_Grades', this.applicationDetails.grades); 
        });
    }

    renderIndustriesDropdown(data, dd, active) {
        for (var v = 0; v < data.length; v++) {
            var optgroup = $('<optgroup>', { 'label': data[v].title, "data-id": data[v].uniqueId });
            optgroup.appendTo(dd);
            this.renderDropdown(data[v].subIndustries, optgroup, active);
        }

        this.finishDropdownUpdate('#Filter_Industries');
    }

    dropdownSetSelected(dd, items) {
        if (items == undefined)
            return;

        for (var i = 0; i < items.length; i++) {
            const value = items[i].id || items[i].urlSlug;
            $(dd).find("option[value=" + value + "]").prop("selected", "selected");
        }

    }

    renderDropdown(data, dd, active) {
       
        for (var v = 0; v < data.length; v++) {
            if (data[v].urlSlug != undefined) {
                $('<option>', { 'value': data[v].urlSlug, "data-id": data[v].urlSlug }).text(data[v].title).appendTo(dd);
            } else {
                $('<option>', { 'value': data[v].uniqueId, "data-id": data[v].uniqueId }).text(data[v].title).appendTo(dd);
            }
        }

        this.dropdownSetSelected(dd, active);
        //don't fire for industry optgroups
        if (typeof dd !== "object"){
            this.finishDropdownUpdate(dd);
        }
    }

    finishDropdownUpdate(dd){
        $(dd).trigger('change');
        $(dd).removeAttr('data-noupdate');
    }

    delete() {
        if (this.applicationDetails !== undefined) {
            $.post("/api/applications/delete/" + this.applicationDetails.id).then(function () {
                window.location.href = '/application-case-studies/list';
            });
        }
    }

    unpublish() {
        const { id } = this.applicationDetails;
        if (this.applicationDetails !== undefined) {
            $.post("/api/applications/unpublish/" + id).then(() => {
                this.unpublishDialog.close();
                this.getApplication(id);
            });
        }
    }

    saveToJson(publish) {
        var json = {}
        json.published = publish;
        if (this.applicationDetails !== undefined) {
            json.Id = this.applicationDetails.id;
        }
        json.publishedAt = this.applicationDetails.publishedAt;
        json.title = $('#Title').val();
        json.url = $('#URL').val();
        json.thumbnail = $('#Thumbnail').attr('data-src');

        json.pdfDoc = $('#pdfDoc').val();
        json.disclaimer = $('#Disclaimer').val();
        json.contacts = [];
        json.filters = {
            "industries": $("#Filter_Industries :selected").map(function (i, el) {
                return { id: $(el).val(), title: $(el).text() };
            }).get(),
            "brands": $("#Filter_Brands :selected").map(function (i, el) {
                return { id: $(el).val(), title: $(el).text() };
            }).get(),
            "features": $("#Filter_Features :selected").map(function (i, el) {
                return { id: $(el).val(), title: $(el).text() };
            }).get(),
            "applications": $("#Filter_Applications :selected").map(function (i, el) {
                return { id: $(el).val(), title: $(el).text() };
            }).get(),
            "sustainability": $("#Filter_Sustainability :selected").map(function (i, el) {
                return { id: $(el).val(), title: $(el).text() };
            }).get()
        }

        json.products = $("#Filter_Products :selected").map(function (i, el) {
            return { id: $(el).val(), title: $(el).text() };
        }).get();

        json.grades = $("#Filter_Grades :selected").map(function (i, el) {
            return { urlSlug: $(el).val(), title: $(el).text() };
        }).get();

        json.contacts = this.getContactDetails();

        json.blocks = [];
        var blockLength = $('.editor-blockItem').length;

        for (var v = 0; v < blockLength; v++) {
            json.blocks.push(this.blockEditor.getBlockData($('.editor-blockItem')[v], v));
        }
        return json;
    }

    getContactDetails() {
        var contactDetails = [];
        var $contactItems = $('[data-contact-item]');

        $contactItems.map(function (i, el) {

            var region = $(el).find('[data-region-input]').val(),
                name = $(el).find('[data-office-input]').val(),
                phone = $(el).find('[data-tel1-input]').val(),
                phone2 = $(el).find('[data-tel2-input]').val(),
                email = $(el).find('[data-email-input]').val();

            if ((region + name + phone + phone2 + email).trim() !== "") {
                contactDetails.push({
                    "region": region,
                    "name": name,
                    "phone": phone,
                    "phone2": phone2,
                    "email": email,
                });
            }
        })

        return contactDetails;
    }

    formatDate(date) {

        const pad = (num) => {
            if (num > 9) {
                return num;
            } else {
                return "0" + num;
            }
        }

        const dt = new Date(date);

        return pad(dt.getDate()) + "/" + pad(dt.getMonth() + 1) + "/" + dt.getFullYear() + " " + pad(dt.getHours()) + ":" + pad(dt.getMinutes());
    }

    populateHeaderInfo() {

        if (!this.applicationDetails) return;

        const { created, createdBy, updated, updatedBy, published, publishedAt } = this.applicationDetails;
        const $el = $('[data-article-header]');

        var headerInfo = "";
        if (created) {
            headerInfo += `<p>Created: <strong>${this.formatDate(created)}</strong> by <strong>${createdBy}</strong></p>`;
        }
        if (updated) {
            headerInfo += `<p>Updated: <strong>${this.formatDate(updated)}</strong> by <strong>${updatedBy}</strong></p >`;
        }

        if (published && publishedAt) {
            headerInfo += `<p>Last published: <strong>${this.formatDate(publishedAt)}</strong> by <strong>${updatedBy}</strong>`;
        }

        $el.html(headerInfo);
    }

}

export default applicationsEditor;