"use strict";

import '../css/style.scss';
import $ from 'jquery';
import './libs/duDatepicker.js';
import './libs/tablednd.js';
import EditUser from './edituser.js';
import ApplicationsEditor from './applications-editor.js';
import EditMember from './editMember.js';
import EditDistributor from './editDistributor.js';
import MemberStatus from './custom.js';
import NetZero from './netZero.js';
import UserMenu from './usermenu.js';
import MenuItems from './menu.js';
import GradeVisibility from './grade-visibility.js';
import CheckboxFilters from './checkboxfilter.js';
import SelectFilters from './selectfilter.js';
import DatePicker from './datepicker.js';
import {MDCDialog} from '@material/dialog';
import validate from './libs/jquery.validate.min.js';
import validateUnobtrusive from './libs/jquery.validate.unobtrusive.min.js';
if (mdc !== undefined) {
    console.log("INIT MATERIAL");
	mdc.autoInit();
}

$.validator.addMethod("fileupload", function (value, element, params) {
    return ($(element).attr('data-src') !== undefined);
});

$.validator.unobtrusive.adapters.addBool('fileupload');
$.validator.unobtrusive.parse(document);


(function() {

    var App = {}
    
    App.init = function () {

      

        if ($('#applications-form').length){
            App.ApplicationsEditor = new ApplicationsEditor();
        }
        
        if ($("#navigation-bar").length) {
            UserMenu.init();
            MenuItems.init();
        }
		
        if ($("#deleteUser").length) {
            EditUser.init();
        }
        if ($("#deleteMember").length) {

            EditMember.init();
        }
        if ($("#deleteDistributor").length) {
            EditDistributor.init();
        }

        if ($('[data-vis-enable]').length || $('[data-vis-disable]').length) {
            GradeVisibility.init();
        }

        if ($('.member-status').length) {
      //      MemberStatus.init();
        }

        if ($(".table-filter .checkbox").length) {
            CheckboxFilters.init();
        }
        if ($(".table-filter .select").length) {
            SelectFilters.init();
        }

        if ($(".table-filter .date").length) {
            DatePicker.init();
        }

        if ($(".application-details-tabs").length) {
            App.initTabs();
        } 

        if ($(".details-page").length) {
            App.initDetails();
        }
        
        if ($("#netzero").length) {
            NetZero.init();
        }
        

        $('[data-expander]').on('click', function(e) {
            $(this).toggleClass('active');
            $(this).parents('[data-expandee]').toggleClass('active');
        })

	}

  
	App.init();

})($);
