import { MDCDialog } from '@material/dialog';

let EditMember = {

    "init": function () {
        const confirmDeleteMemberDialog = new MDCDialog(document.querySelector('#confirmDeleteMember #confirm-delete-member-mdc-dialog'));
        const deleteMemberDialog = new MDCDialog(document.querySelector('#deleteMemberDialog #delete-member-mdc-dialog'));

        $("#deleteMember").click(function (evt) {
            confirmDeleteMemberDialog.lastFocusedTarget = evt.target;
            confirmDeleteMemberDialog.open();
        });

        // Confirm deleting
        $("#ConfirmDeleteMember").click(function (evt) {
            confirmDeleteMemberDialog.close();

            $.ajax({
                url: "/api/members/delete/" + memberId,
                type: "POST",

                success: function () {
                    window.location = window.location.origin + redirectMemberUrl;
                },

                error: function (e) {
                    deleteMemberDialog.open();

                    var content = $("#deleteMemberDialog #delete-member-mdc-dialog-description");

                    if (e.status == 400) {
                        content.html(e.responseJSON.message);

                    } else {
                        content.html("Currently Unavailable, please try again");
                    }
                }
            });
        });

        // Cancel dialog
        $(".mdc-dialog__footer__button--cancel").click(function (evt) {
            confirmDeleteMemberDialog.close();
            deleteMemberDialog.close();
        });
    }
}

export default EditMember;
