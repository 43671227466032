let GradeVisibility = {
    "init":  function(){
        GradeVisibility.setEvents();
    },
    "setEvents": function(){
        $("[data-vis-enable]").on('click', function (evt) {
            console.log( this.dataset);
            const gradeId = this.dataset.gradeId;
            GradeVisibility.setGradeVisibility(gradeId, true);
           
        });

        $("[data-vis-disable]").on('click', function (evt) {
            console.log( this.dataset);
            const gradeId = this.dataset.gradeId;
            GradeVisibility.setGradeVisibility(gradeId, false);
           
        });
    },
    "setGradeVisibility": function(gradeId, visibility){
        $.ajax({
            url: window.gradeEuclidUrl + gradeId + "/" + visibility,
            type: "POST",

            success: function () {
                const $row = $('[data-grade-row="'+gradeId+'"]');
                $row.removeClass('vis-disabled').removeClass('vis-enabled');
                $row.addClass((visibility)?'vis-enabled':'vis-disabled');
                $row.find('.status').text((visibility)?'Enabled':'Disabled');
            },

            error: function (e) {
                alert("An error occurred updating the visibility of this grade.")
                console.log(e);
            }
        });
    }
}

export default GradeVisibility;
