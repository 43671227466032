import { MDCDialog } from '@material/dialog';

let EditDistributor = {

    "init": function () {
        const confirmDeleteDistributorDialog = new MDCDialog(document.querySelector('#confirmDistributor #confirm-delete-distributor-mdc-dialog'));
        const deleteDistributorDialog = new MDCDialog(document.querySelector('#deleteDistributorDialog #delete-distributor-mdc-dialog'));

        $("#deleteDistributor").click(function (evt) {
            confirmDeleteDistributorDialog.lastFocusedTarget = evt.target;
            confirmDeleteDistributorDialog.open();
        });

        // Confirm deleting
        $("#ConfirmDeleteDistributor").click(function (evt) {
            confirmDeleteDistributorDialog.close();

            $.ajax({
                url: "/api/distributors/delete/" + distributorId,
                type: "POST",

                success: function () {
                    window.location = window.location.origin + redirectDistributorUrl;
                },

                error: function (e) {
                    deleteDistributorDialog.open();

                    var content = $("#deleteDistributorDialog #delete-distributor-mdc-dialog-description");

                    if (e.status == 400) {
                        content.html(e.responseJSON.message);

                    } else {
                        content.html("Currently Unavailable, please try again");
                    }
                }
            });
        });

        // Cancel dialog
        $(".mdc-dialog__footer__button--cancel").click(function (evt) {
            confirmDeleteDistributorDialog.close();
            deleteDistributorDialog.close();
        });
    }
}

export default EditDistributor;
