import trumbowyg from 'trumbowyg';
import Sortable from 'sortablejs';


class blockEditor {
    constructor(id, data){
        this.id = id;
        this.$el = $('#' + id);
        this.$blockList = $('#blockEditor-blockList');
        this.blockTemplates = {};
        this.bindEvents();
        this.getBlockTemplates();
        this.sortable = this.makeSortable();
        this.applicationDetails = data;
      
        this.blockTemplate = $('#block').html();
        $.trumbowyg.svgPath = '/dist/img/icons.svg';

    }

    clearAllBlocks() {
        $('#blockEditor-blockList').html('');
    }

    updateData(data) {
        this.clearAllBlocks();
        this.applicationDetails = data;
    }

    bindEvents(){
        const that = this;
        $('[data-block-add]').on('click', function (e) {
            e.preventDefault();
            const blockTemplate = $(this).attr('data-block-type');
            const position = $(this).attr('data-block-add');
            that.postAdd(that.createEditorBlock(blockTemplate, position));
        });

        $('body').on('click', '[data-remove-block]', function (e) {
            $(this).parents('li').remove();
            that.postRemove();
        });

    
    }

    
    postAdd(el) {
        $(el).find('[data-wysiwyg]').trumbowyg($.trumbowyg.opts);
        if (this.$blockList.find('li').length > 0) {
            $('.button-bar.bottom').addClass('view');
        }
    }

    

    postRemove() {
        if (this.$blockList.find('li').length == 0) {
            $('.button-bar.bottom').removeClass('view');
        }
    }

    
    makeSortable() {
        if (document.getElementById("blockEditor-blockList")) {
            return Sortable.create(document.getElementById("blockEditor-blockList"), {
                handle: '.handle'
            });
        }
    }

    getBlockTemplates() {
        $('template.custom-block').map((i, el) => {
            var name = $(el).attr('id');
            this.blockTemplates[name] = $(el).html();
        });
    }

    getTemplate(template) {
        var ret = $('<div />').html(this.blockTemplate);

        ret.find('.block-contents').html(this.blockTemplates[template]);
        return ret.html();
    }

  
    createEditorBlock(blockTemplate, position) {
        const templateHtml = this.getTemplate(blockTemplate);
        var ret = undefined;
        var el = $('<li/>', {
            class: 'editor-blockItem'
        }).html(templateHtml);

        if (position == "top") {
            ret = this.$blockList.prepend(el);
        } else {
            ret = this.$blockList.append(el);
        }

        return ret;
    }
    

    getBlockData(el,order) {
        const $el = $(el);
        var type = $el.find('[data-block-type]').attr('data-block-type');
        var fields = $el.find('[data-field-name]');
        var ret = {
            type: type,
            text1: '',
            text2: '',
            imageUrl: '',
            order
        }
        fields.each((i, el) => {
            var val = $(el).val();
            if ($(el).attr('data-src') !== undefined){
                val = $(el).attr('data-src')
            }
            ret[$(el).attr('data-field-name')] = val;
        });
        return ret;
    }
}

export default blockEditor;