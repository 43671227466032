
let DatePicker = {
    "init": function () {
        $('#FromDate').duDatepicker({ format: 'dd-mm-yyyy', rangeTo: '#ToDate' });
        $('#ToDate').duDatepicker({ format: 'dd-mm-yyyy', rangeFrom: '#FromDate' });

        $('#FromDate').on('datechanged', datePickerChange);
        $('#ToDate').on('datechanged', datePickerChange);

        $('#FromDate').click(function () {
            checkDatePickerContainer('FromDate');
            $(".FromDate-label").addClass("mdc-floating-label--float-above");
            $(".FromDate.mdc-text-field").addClass("mdc-text-field--focused");
            $(".FromDate.mdc-line-ripple").addClass("mdc-line-ripple--active").removeClass("mdc-line-ripple--deactivating");
        });

        $('#ToDate').click(function () {
            checkDatePickerContainer('ToDate');

            $(".ToDate-label").addClass("mdc-floating-label--float-above");
            $(".ToDate.mdc-text-field").addClass("mdc-text-field--focused");
            $(".ToDate.mdc-line-ripple").addClass("mdc-line-ripple--active").removeClass("mdc-line-ripple--deactivating");
        });

        function datePickerChange(e) {
            var id = $(this).attr('id');
            this.parentNode.MDCTextField.value = this.value || "";
     
        }

        function checkDatePickerContainer(obj) {
            if ($('.dudp__wrapper').is(':visible')) { //if the container is visible on the page
                $(".dudp__wrapper").css("cssText", "bottom: auto !important;");

                $(".dudp__wrapper").css("top", (document.getElementById(obj).getBoundingClientRect().top + 30) + "px");
                $(".dudp__wrapper").css("left", (document.getElementById(obj).getBoundingClientRect().left + 80) + "px");

            } else {
                setTimeout(checkDatePickerContainer, 500); //wait 500 ms, then try again
            }
        };
    }
}

export default DatePicker;
